<template>
  <div>
    <WsMain>
      <WsText
        size="12"
        class="mb-8"
        :color="$color.gray"
      >頭像</WsText>
      <WsAvatar
        size="120"
        :src="_currentUser.avatar"
      ></WsAvatar>
      <WsInfo
        label="名稱"
        :value="_currentUser.name"
      ></WsInfo>
      <WsInfo
        label="權限"
        type="multi-select"
        :items="_scopeItems"
        :value="_currentUser.scopes"
      ></WsInfo>
      <WsBtn
        class="mt-40"
        to="/logout"
      >登出</WsBtn>
    </WsMain>
  </div>
</template>

<script>
export default {
  computed: {
    _currentUser() {
      return this.$store.state.auth.currentUser;
    },
    _scopeItems() {
      return this.$config.auth.scope.list;
    },
  },
};
</script>

<style>
</style>